.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo-container {
  height: 180px;
  place-content: center;
  //background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  display: flex;
  // justify-content: flex-start;
  align-content: center;
  .logo-icon {
    height: 133px;
    user-select: none;
  }
  .logo-icon-leaf {
    margin: 0;
   width: 60px;
    user-select: none;
  }
}
.logo-container-collapsed {
  //width: 5vw !important;
  height: 8.25% !important;
    .logo-icon {
    height: 60px;
    user-select: none;
  }
    .logo-icon-leaf {
    margin: 0;
   width: 0;
    user-select: none;
  }
}

.site-layout .site-layout-background {
  background: #fff;
}

.link-color {
  color: #fff !important;
}

.customSpin {
  max-height: 1080px !important;
}
