.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 112012 !important;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.row-active {
  background-color: rgba(34, 139, 34, 0.2);
  background:  rgba(34, 139, 34, 0.2);
}
.row-deactive {
  background-color: "";
  background: "";

}
