.login-page-container {
  display: flex;
  justify-content: flex-start;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25% !important;
  .loginform {
    width: 100% !important;
  }
}
.login-page-container{
  width: 335px !important;
  .loginform{
    text-align-last: center !important;
  }
}
  .login-button{
  height: 48px !important;
  width: 152px !important;
  border-radius: 12px !important;
  background-color: #00673A !important;
  }
  .site-form-item-icon {
    text-align-last: auto!important;
      height: 45px!important;
  width: 335px !important;
  border-radius: 11px !important;
  background-color: #FFFFFF !important;
  }
  .login-form-forgot{
    color:#FFFFFF
  }

  @font-face {
  font-family: 'Publica Sans';
  src: local('PublicaSans-Black'), url(../../fonts/PublicaSans-Black.otf) format('opentype');
  src: local('PublicaSans-Ligh'), url(../../fonts/PublicaSans-Black.otf) format('opentype');
  src: local('PublicaSans-Medium'), url(../../fonts/PublicaSans-Black.otf) format('opentype');
}